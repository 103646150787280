import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./components/navbar/";
import MobileLeftDrawer from "./static/MobileLeftDrawer";
import Footer from "./components/sections/Footer";
import SwitchRoutes from "./routes/switches/SwtichRoutes";
import { Provider } from "react-redux";
import store from "./redux/Store";

export default function App() {
  return (
    <Provider store={store}>
      <Router>
        <Navbar />
        <MobileLeftDrawer />
        <SwitchRoutes />
        <Footer />
      </Router>
    </Provider>
  );
}
