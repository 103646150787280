export const openLeftDrawer = () => {
  return {
    type: "OPEN_DRAWER",
  };
};
export const closeLeftDrawer = () => {
  return {
    type: "CLOSE_DRAWER",
  };
};
