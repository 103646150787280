import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ProgrammerGuy from "../../../assets/apple-touch-icon.png";
import { createBrowserHistory } from "history";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeLanguage } from "../../../redux/language/Actions";

export default function Navbar() {
  const { t, i18n } = useTranslation();
  const history = createBrowserHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const currentRoute = history.location.pathname;
    const notHomeRoutes = currentRoute.substr(1, currentRoute.length);
    setUnderBorderOrder(
      currentRoute === "/" ? currentRoute : notHomeRoutes,
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMenuItemClick = (item, e, target = null) => {
    const li = e.target.parentNode; // Get parent node element
    if (target == null) {
      li.classList.add("liBordered"); // Set under border
      setUnderBorderOrder(item); // Delete under border from another elements
    } else {
      const home = document.getElementById("HomeButton");
      home.classList.add("liBordered");
      setUnderBorderOrder("Home");
    }
  };

  const setUnderBorderOrder = (item, onload = false) => {
    const home = document.getElementById("HomeButton");
    const about = document.getElementById("aboutMeButton");
    const projects = document.getElementById("recentlyDoneProjectsButton");
    const abilities = document.getElementById("myAbilitiesButton");
    const contacts = document.getElementById("ContactsMenuButton");
    if (item === "Home" || item === "/") {
      if (onload) home.classList.add("liBordered");
      about.classList.remove("liBordered");
      projects.classList.remove("liBordered");
      abilities.classList.remove("liBordered");
      contacts.classList.remove("liBordered");
    }
    if (item === "About-me") {
      if (onload) about.classList.add("liBordered");
      home.classList.remove("liBordered");
      projects.classList.remove("liBordered");
      abilities.classList.remove("liBordered");
      contacts.classList.remove("liBordered");
    }
    if (item === "Projects" || item === "Projects/") {
      if (onload) projects.classList.add("liBordered");
      home.classList.remove("liBordered");
      about.classList.remove("liBordered");
      abilities.classList.remove("liBordered");
      contacts.classList.remove("liBordered");
    }
    if (item === "Abilities") {
      if (onload) abilities.classList.add("liBordered");
      home.classList.remove("liBordered");
      about.classList.remove("liBordered");
      projects.classList.remove("liBordered");
      contacts.classList.remove("liBordered");
    }
    if (item === "Contacts") {
      if (onload) contacts.classList.add("liBordered");
      home.classList.remove("liBordered");
      about.classList.remove("liBordered");
      projects.classList.remove("liBordered");
      abilities.classList.remove("liBordered");
    }
  };

  function onLanguageChange(e) {
    const language = e.target.value;
    dispatch(changeLanguage(language.toLowerCase()));
    i18n.changeLanguage(language.toLowerCase());
  }

  function openUrl(url) {
    window.open(url, "_blank");
  }
  return (
    <div id="menuScrollTime">
      <div className="container">
        <ul className="menuOptionsFixed">
          <li>
            <Link to="/" onClick={(e) => onMenuItemClick("Icon", e, "icon")}>
              <img
                src={ProgrammerGuy}
                width="40"
                alt="Programmer guy | Tohir.lt"
              />
            </Link>
          </li>
          <li id="HomeButton">
            <Link to="/" onClick={(e) => onMenuItemClick("Home", e)}>
              {t("NavigationItems.1")}
            </Link>
          </li>
          <li id="aboutMeButton">
            <Link
              to="/About-me"
              onClick={(e) => onMenuItemClick("About-me", e)}
            >
              {t("NavigationItems.2")}
            </Link>
          </li>
          <li id="recentlyDoneProjectsButton">
            <Link
              to="/Projects"
              onClick={(e) => onMenuItemClick("Projects", e)}
            >
              {t("NavigationItems.3")}
            </Link>
          </li>
          <li id="myAbilitiesButton">
            <Link
              to="/Abilities"
              onClick={(e) => onMenuItemClick("Abilities", e)}
            >
              {t("NavigationItems.4")}
            </Link>
          </li>
          <li id="ContactsMenuButton">
            <Link
              to="/Contacts"
              onClick={(e) => onMenuItemClick("Contacts", e)}
            >
              {t("NavigationItems.5")}
            </Link>
          </li>
          <li onClick={() => openUrl("https://github.com/Tohir-Abdujaborov")}>
            <i className="fa fa-github fa-lg"></i>
            &nbsp; GitHub
          </li>
          <li>
            <select className="languageSelector" onChange={onLanguageChange}>
              <option>EN</option>
              <option>LT</option>
            </select>
          </li>
        </ul>
      </div>
    </div>
  );
}
