import { useTranslation } from "react-i18next";
import Devider from "../../../ui/Devider";
import { contentMargins } from "../../../styles/Globals";

export default function Mobile() {
  const { t } = useTranslation();
  return (
    <section id="mobileWorldTable">
      <Devider marginBetween={contentMargins.marginTop} />
      <div className="container">
        <div className="welcomeText">{t("Home.welcome")}</div>
        <div className="welcomeDescriptionMobile text-center mt-5">
          {t("Home.welcomeText")}
        </div>
      </div>
    </section>
  );
}
