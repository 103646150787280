import { useState } from "react";
import Devider from "../ui/Devider";
import { contentMargins } from "../styles/Globals";
import jobBanners from "../assets/job-banners.png";
import { useTranslation } from "react-i18next";
import BlockSpinner from "../components/spinner/Block";

export default function AboutMe() {
  const { t } = useTranslation();

  const [jobBannersLoad, setJobBanersLoad] = useState(false);

  return (
    <section className="container" id="aboutMe">
      <Devider marginBetween={contentMargins.marginTop} />
      <div className="row">
        <div className="col-md-7">
          <BlockSpinner isVisible={!jobBannersLoad} size={50} />
          <img
            src={jobBanners}
            className="img-fluid"
            alt="Programmer guy illustration | Tohir.lt"
            onLoad={() => setJobBanersLoad(true)}
          />
        </div>
        <div className="col-md-5">
          <div className="introduce-container">
            <div className="introduceBig text-center">
              {t("AboutMe.aboutMe")}
            </div>
            <Devider marginBetween={25} />
            <div className="introduceDescription text-center">
              <span>{t("AboutMe.motivationText1")}</span>
              <Devider marginBetween={25} />
              <span>{t("AboutMe.motivationText2")}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
