import { css } from "@emotion/react";

export const contentMargins = {
  marginTop: 100,
  footerMarginTop: 100,
  noTopMargin: 65,
};

export const ClipLoaderDesktop = css`
  position: fixed;
  top: 40%;
  left: 45%;
`;

export const ClipLoaderMobile = css`
  position: fixed;
  top: 50%;
  left: 40%;
`;

export const ClipLoaderStyleBlocked = css`
  position: relative;
  top: 47%;
  left: 47%;
`;
