import React, { useEffect, useState, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import programmerGuy from "../../../assets/2.png";
import BlockSpinner from "../../spinner/Block";

export default function Home() {
  const { t } = useTranslation();
  const [welcomeStyles, setWelcomeStyles] = useState({});
  const [programmerGuyLoad, setprogrammerGuyLoad] = useState(false);

  useEffect(() => {
    setWelcomeStyles({ width: window.innerWidth - 20 + "px" });
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setWelcomeStyles({ width: window.innerWidth - 20 + "px" });
    });
  }, []);

  return (
    <section className="Home">
      <center>
        <section id="worldTableSection">
          <table
            border="0"
            cellPadding="0"
            cellSpacing="0"
            style={welcomeStyles}
          >
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>
                  <div className="container">
                    <div className="welcomeText">{t("Home.welcome")}</div>
                    <div className="welcomeDescription">
                      {t("Home.welcomeText")}
                    </div>
                  </div>
                </td>
                <td style={{ width: "50%" }}>
                  <BlockSpinner isVisible={!programmerGuyLoad} size={50} />
                  <img
                    src={programmerGuy}
                    className="rightImage"
                    alt="Programmer Guy | Tohir.lt"
                    onLoad={() => setprogrammerGuyLoad(true)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </center>
    </section>
  );
}
