import ClipLoader from "react-spinners/ClipLoader";
import { ClipLoaderStyleBlocked } from "../../styles/Globals";

const Spinner = (props) => {
  return (
    <ClipLoader
      loading={props.isVisible}
      size={props.size}
      css={ClipLoaderStyleBlocked}
      color="#673ab7"
    />
  );
};
export default Spinner;
