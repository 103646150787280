import React, { useEffect, useLayoutEffect, useState } from "react";
import Desktop from "../components/home/desktop";
import Mobile from "../components/home/mobile";

export default function Home() {
  const [isMobile, setMobile] = useState(false);
  useEffect(() => {
    let windowWitdh = window.innerWidth;
    if (windowWitdh > 1006) setMobile(false);
    else setMobile(true);
  }, []);
  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      let windowWitdh = window.innerWidth;
      if (windowWitdh > 1006) setMobile(false);
      else setMobile(true);
    });
  }, []);
  if (!isMobile) return <Desktop />;
  else return <Mobile />;
}
