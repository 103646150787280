import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Devider from "../ui/Devider";
import { contentMargins } from "../styles/Globals";
import Image from "../components/abilities/Image";

export default function Abilities() {
  const { t } = useTranslation();
  const chevronsSpace = {
    width: "50px",
    cursor: "pointer",
  };
  const cardsImages = [
    "html.png",
    "css.jpg",
    "javascript.svg",
    "react.png",
    "vuejs.png",
    "php.svg",
    "sql.svg",
    "wordpress.png",
    "bootstrap.png",
    "photoshop.jpg",
    "git.png",
    "java.png",
  ];
  const abilityLanguages = [
    { icon: "flags/Lithuania_flag.png", language: t("Abilities.lithuanian") },
    { icon: "flags/USA_flag.jpg", language: t("Abilities.english") },
    { icon: "flags/Russia_flag.png", language: t("Abilities.russian") },
  ];
  const myAbilitiesContent = document.getElementById("myAbilitiesContent");
  const [abWitdh, setAbWitdh] = useState(0);

  useEffect(() => {
    const windowWitdh = window.innerWidth;
    setAbWitdh(windowWitdh - 100);
  }, []);

  function onClickChevron(side) {
    const scrollLeftPosition = myAbilitiesContent.scrollLeft;
    myAbilitiesContent.scrollTo({
      left:
        side === "right" ? scrollLeftPosition + 290 : scrollLeftPosition - 290,
      behavior: "smooth",
    });
  }
  return (
    <section>
      <Devider marginBetween={contentMargins.noTopMargin} />
      <div className="AbilitiesSection">
        <div className="myAbilitiesTitle">
          {t("Abilities.myAbilities") || "My Abilities"}
        </div>
        <Devider marginBetween={20} />
        <table
          border="0"
          cellPadding="0"
          cellSpacing="0"
          className="myAbilitiesTable"
        >
          <tbody>
            <tr>
              <td style={chevronsSpace} onClick={() => onClickChevron("left")}>
                <center>
                  <i className="fa fa-chevron-left fa-lg chevrons"></i>
                </center>
              </td>
              <td>
                <div
                  style={{ width: abWitdh + "px" }}
                  className="myAbilitiesContent"
                  id="myAbilitiesContent"
                >
                  <table
                    border="0"
                    cellPadding="0"
                    cellSpacing="0"
                    className="myAbilitiesTable"
                  >
                    <tbody>
                      <tr>
                        {cardsImages.map((item) => (
                          <td key={item}>
                            <div
                              className="card"
                              style={{
                                width: "18rem",
                                marginRight: item !== "java.png" ? 30 : 0,
                              }}
                            >
                              <Image image={item} />
                            </div>
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
              <td style={chevronsSpace} onClick={() => onClickChevron("right")}>
                <center>
                  <i className="fa fa-chevron-right fa-lg chevrons"></i>
                </center>
              </td>
            </tr>
          </tbody>
        </table>
        <Devider marginBetween={100} />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md">
              <img
                src={"/subject.png"}
                className="img-fluid subjectImg"
                width="250"
                alt="Icon of language | tohir.lt"
              />
              <Devider marginBetween={30} />
              <div
                className="abilitiesLanguagesTitle"
                dangerouslySetInnerHTML={{
                  __html: t("Abilities.languagesISpeak"),
                }}
              ></div>
            </div>
            <div className="col-md">
              <div className="languageContainerParent">
                {abilityLanguages.map((item) => (
                  <div key={item.language}>
                    <div className="languagesContainer">
                      <div>
                        <img
                          src={"/" + item.icon}
                          width="50"
                          alt={item.language}
                        />
                      </div>
                      <div>
                        <span id="abilitiesLanguage">{item.language}</span>
                      </div>
                    </div>
                    {item.icon !== "Russia_flag.png" ? (
                      <Devider marginBetween={70} />
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
