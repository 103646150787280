import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeLanguage } from "../redux/language/Actions";
import { useHistory } from "react-router-dom";
import { closeLeftDrawer } from "../redux/menu/Actions";
import { useTranslation } from "react-i18next";

export default function MobileLeftDrawer() {
  const menuState = useSelector((state) => state.menu.isLeftDrawerOpen);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const route = useHistory();

  const menuOpen = { left: 0 };
  const menuClosed = { left: "-355px" };

  const changeRoute = (path) => {
    switch (path) {
      case "home":
        route.push("/");
        break;
      case "about":
        route.push("/About-me");
        break;
      case "projects":
        route.push("/Projects");
        break;
      case "abilities":
        route.push("/Abilities");
        break;
      case "contacts":
        route.push("/Contacts");
        break;
      default:
    }
    hideLeftDrawer();
    document.body.style.overflow = "scroll";
  };

  const hideLeftDrawer = () => {
    dispatch(closeLeftDrawer());
  };
  const onLanguageChange = (language) => {
    dispatch(changeLanguage(language.toLowerCase()));
    i18n.changeLanguage(language.toLowerCase());
  };
  return (
    <section
      className="mobileMenuContent"
      style={menuState ? menuOpen : menuClosed}
    >
      <div className="mobileMenuLanguageLinks text-center mt-4">
        <span onClick={() => onLanguageChange("lt")}>LT</span>|
        <span onClick={() => onLanguageChange("en")}>EN</span>
      </div>
      <ul className="mt-4">
        <li onClick={() => changeRoute("home")}>{t("NavigationItems.1")}</li>
        <li onClick={() => changeRoute("about")}>{t("NavigationItems.2")}</li>
        <li onClick={() => changeRoute("projects")}>
          {t("NavigationItems.3")}
        </li>
        <li onClick={() => changeRoute("abilities")}>
          {t("NavigationItems.4")}
        </li>
        <li onClick={() => changeRoute("contacts")}>
          {t("NavigationItems.5")}
        </li>
        <li>
          <i className="fa fa-github fa-lg"></i>
          &nbsp;Github
        </li>
      </ul>
    </section>
  );
}
