import { useState } from "react";
import BlockSpinner from "../spinner/Block";
import Devider from "../../ui/Devider";

const Image = (props) => {
  const [loading, setLoading] = useState(true);
  const loadingVsbToggle = () => {
    return (
      <>
        <Devider marginBetween={70} />
        <BlockSpinner isVisible={true} size={50} />
        <Devider marginBetween={70} />
      </>
    );
  };
  return (
    <>
      {loading ? loadingVsbToggle() : ""}
      <img
        src={"/programmingLanguages/" + props.image}
        className="card-img-top"
        alt="..."
        onLoad={() => setLoading(false)}
      />
    </>
  );
};
export default Image;
