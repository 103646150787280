import ClipLoader from "react-spinners/ClipLoader";
import { ClipLoaderDesktop, ClipLoaderMobile } from "../../styles/Globals";
import { isMobile } from "react-device-detect";

const Spinner = () => {
  return (
    <ClipLoader
      loading={true}
      size={100}
      css={isMobile ? ClipLoaderMobile : ClipLoaderDesktop}
      color="#673ab7"
    />
  );
};
export default Spinner;
