import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProgrammerGuy from "../../../assets/apple-touch-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { openLeftDrawer, closeLeftDrawer } from "../../../redux/menu/Actions";

export default function MobileNavbar() {
  const [drawer, setDrawer] = useState("close");

  const drawerState = useSelector((state) => state.menu.isLeftDrawerOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    drawerState ? setDrawer("open") : setDrawer("close");
  }, [drawerState]);

  const onOpenLeftDrawer = () => {
    if (drawerState) {
      setDrawer("close");
      dispatch(closeLeftDrawer());
      document.body.style.overflow = "scroll";
    } else if (!drawerState) {
      setDrawer("open");
      dispatch(openLeftDrawer());
      document.body.style.overflow = "hidden";
    }
  };
  return (
    <section className="mobileNavbarContainer px-3 py-2">
      <div>
        <Link to="/">
          <img src={ProgrammerGuy} width="40" alt="Programmer guy | Tohir.lt" />
          <span className="title">Tohir.lt</span>
        </Link>
      </div>
      <div
        id="mobileMenuButton"
        className={drawer}
        onClick={() => onOpenLeftDrawer()}
      >
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
    </section>
  );
}
