import React, { useEffect, useLayoutEffect, useState } from "react";
import DesktopNavbar from "./desktop";
import MobileNavbar from "./mobile";

export default function Navbar() {
  const [isMobile, setMobile] = useState(false);
  useEffect(() => {
    let windowWitdh = window.innerWidth;
    if (windowWitdh > 1006) setMobile(false);
    else setMobile(true);
  }, []);
  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      let windowWitdh = window.innerWidth;
      if (windowWitdh > 1006) setMobile(false);
      else setMobile(true);
    });
  }, []);
  if (!isMobile) return <DesktopNavbar />;
  else return <MobileNavbar />;
}
