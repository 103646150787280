import React, { Component } from "react";

export default class Devider extends Component {
  render() {
    return (
      <div
        style={{
          paddingTop: this.props.marginBetween / 2,
          paddingBottom: this.props.marginBetween / 2,
        }}
      ></div>
    );
  }
}
