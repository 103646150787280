import { useState } from "react";
import { useTranslation } from "react-i18next";
import Devider from "../../ui/Devider";
import BlockSpinner from "../spinner/Block";

const Project = (props) => {
  const { t } = useTranslation();
  const openProject = t("Projects.openThisProject");

  const [isProjImgLoaded, setProjImgLoad] = useState(false);

  const setProgrammingLanguage = (type) => {
    const imgFolder = "programmingLanguages/";
    switch (type) {
      case "php":
        return imgFolder + "php_elephant.jpg";
      case "javascript":
        return imgFolder + "javascript.svg";
      case "react-native":
        return imgFolder + "react_native.png";
      case "vue-js":
        return imgFolder + "vuejs_icon.png";
      case "react-app":
        return imgFolder + "react.png";
      default:
    }
  };
  const progLanguageIcon = (
    <img
      src={"/" + setProgrammingLanguage(props.progLanguage)}
      width="25"
      title={props.progLanguage}
      alt=""
    />
  );
  const spinner = (
    <>
      <Devider marginBetween={60} />
      <BlockSpinner isVisible={true} size={50} />
      <Devider marginBetween={60} />
    </>
  );
  const androidIcon = (
    <i className="fa fa-android fa-sm" title={props.progLanguage}></i>
  );
  return (
    <div
      className="ProjectCell"
      onClick={() => window.open(props.url, "_blank")}
    >
      {!isProjImgLoaded ? spinner : ""}
      <img
        src={"/projects/" + props.img}
        className="img-fluid projectImage"
        title={openProject}
        alt={props.imgAlt + "|| www.Tohir.lt project"}
        onLoad={() => setProjImgLoad(true)}
      />
      <div className="title">
        {props.progLanguage === "android" ? androidIcon : progLanguageIcon}
        <span>{props.title}</span>
      </div>
      <span className="description">{props.description}</span>
    </div>
  );
};
export default Project;
