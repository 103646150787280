import React, { useState } from "react";
import Devider from "../ui/Devider";
import { contentMargins } from "../styles/Globals";
import { useTranslation } from "react-i18next";
import AllProjects from "../components/projects";
import WebApps from "../components/projects/webApps";
import MobileApps from "../components/projects/mobileApps";

const Projects = () => {
  const { t } = useTranslation();

  const [projectSort, setProjectSort] = useState("all");

  return (
    <section className="Projects container">
      <Devider marginBetween={contentMargins.marginTop} />
      <div className="recentyDoneProjects">
        <span>{t("Projects.recentlyDontProjects")}</span>
      </div>
      <div className="optionButtonsContainer mt-3">
        <ul>
          <li>
            <button
              onClick={() => setProjectSort("all")}
              type="button"
              className={`btn btn-link text-decoration-none ${
                projectSort === "all" ? "buttonBordered" : ""
              }`}
            >
              <i className="fa fa-th fa-lg"></i>
              <span>{t("Projects.all")}</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => setProjectSort("webApps")}
              type="button"
              className={`btn btn-link text-decoration-none ${
                projectSort === "webApps" ? "buttonBordered" : ""
              }`}
            >
              <i className="fa fa-file-code-o fa-lg"></i>
              <span>{t("Projects.webApps")}</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => setProjectSort("mobileApps")}
              type="button"
              className={`btn btn-link text-decoration-none ${
                projectSort === "mobileApps" ? "buttonBordered" : ""
              }`}
            >
              <i className="fa fa-mobile fa-lg"></i>
              <span>{t("Projects.mobileApps")}</span>
            </button>
          </li>
        </ul>
      </div>
      <div className="mt-4">
        {projectSort === "all" ? <AllProjects /> : ""}
        {projectSort === "webApps" ? <WebApps /> : ""}
        {projectSort === "mobileApps" ? <MobileApps /> : ""}
      </div>
    </section>
  );
};
export default Projects;
