import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../Home";
import AboutMe from "../AboutMe";
import Contacts from "../Contacts";
import Abilities from "../Abilities";
import Projects from "../Projects";
import Wifi from "../Wifi";

export default class SwitchRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/About-me" component={AboutMe} />
        <Route path="/Projects" component={Projects} />
        <Route path="/Abilities" component={Abilities} />
        <Route path="/Contacts" component={Contacts} />
        <Route path="/wifi" component={Wifi} />
      </Switch>
    );
  }
}
