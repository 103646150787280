import { useState } from "react";
import Devider from "../ui/Devider";
import { contentMargins } from "../styles/Globals";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Wifi = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [isFormSub, setFormSub] = useState(false);
  const [isCopyClicked, setCopyClick] = useState(false);
  const getWifi = (e) => {
    e.preventDefault();
    setFormSub(true);
    saveToDb(name);
    setName("freewifitohir");
  };
  const copy = () => {
    const input = document.getElementById("input");
    input.select();
    document.execCommand("copy");
    setCopyClick(true);
  };
  const saveToDb = (name) => {
    axios
      .get("https://tohir.lt/api/wifi/", {
        params: {
          name: name,
        },
      })
      .then((Response) => {
        console.log(Response.data);
      })
      .catch((error) => {
        console.log("Network error", error.message);
      });
  };
  return (
    <section className="container">
      <Devider marginBetween={contentMargins.marginTop} />
      <img
        src="/images/free-wifi.png"
        alt="Tohir.lt/wifi"
        className="free-wifi w-200 mx-auto"
      />
      <div className="row align-items-center justify-content-center vertical-center mt-5">
        <div className="col-md-5">
          <div className="wifi-form-container py-4 px-3">
            <div className="wifi_fill mb-3">
              {!isFormSub ? t("Wifi.fillForm") : t("Wifi.getYourPass")}
            </div>
            <form onSubmit={getWifi}>
              <div
                className="wifi_input_wrap mb-3"
                style={isFormSub ? { border: 0, background: "inherit" } : null}
              >
                <i
                  className={`fa ${
                    !isFormSub ? `fa-user-o` : `fa-lock`
                  }  fa-lg my-auto`}
                ></i>
                <input
                  type="text"
                  //{!isFormSub ? "text" : "password"}
                  placeholder={t("Wifi.name")}
                  maxLength="255"
                  className={isFormSub ? "wifi_pass" : ""}
                  id="input"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                />
              </div>
              {!isFormSub ? <button>{t("Wifi.getWifi")}</button> : null}
            </form>
            {isFormSub ? (
              <button onClick={copy}>
                <i
                  className={`fa ${isCopyClicked ? `fa-check` : `fa-files-o`}`}
                ></i>
                &nbsp;&nbsp;
                {isCopyClicked ? t("Wifi.copied") : t("Wifi.copy")}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Wifi;
