const initialState = {
  isLeftDrawerOpen: false,
};
const drawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_DRAWER":
      return {
        isLeftDrawerOpen: true,
      };
    case "CLOSE_DRAWER":
      return {
        isLeftDrawerOpen: false,
      };
    default:
      return state;
  }
};
export default drawerReducer;
