import { useEffect, useLayoutEffect, useState } from "react";
import Project from "../Project";
import { useTranslation } from "react-i18next";
import { PicassoBarbershopApp, MFuel, MyLL } from "../data";

const MobileApps = () => {
  const { t } = useTranslation();

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    let windowWitdh = window.innerWidth;
    windowWitdh < 768 ? setMobile(true) : setMobile(false);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      let windowWitdh = window.innerWidth;
      windowWitdh < 768 ? setMobile(true) : setMobile(false);
    });
  }, []);

  return (
    <div>
      <div className="row">
        <div className={`col-md-3 ${isMobile ? "pb-4" : ""}`}>
          <Project
            img={PicassoBarbershopApp.img}
            imgAlt={PicassoBarbershopApp.img}
            progLanguage={PicassoBarbershopApp.progLanguage}
            title={PicassoBarbershopApp.website}
            description={t("Projects.PicassoBarbershopApp.description")}
            url={PicassoBarbershopApp.url}
          />
        </div>
        <div className={`col-md-3 ${isMobile ? "pb-4" : ""}`}>
          <Project
            img={MFuel.img}
            imgAlt={MFuel.img}
            progLanguage={MFuel.progLanguage}
            title={MFuel.website}
            description={t("Projects.MFuel.description")}
            url={MFuel.url}
          />
        </div>
        <div className="col-md-3">
          <Project
            img={MyLL.img}
            imgAlt={MyLL.img}
            progLanguage={MyLL.progLanguage}
            title={MyLL.website}
            description={t("Projects.MyLL.description")}
            url={MyLL.url}
          />
        </div>
      </div>
    </div>
  );
};
export default MobileApps;
