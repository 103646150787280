export const PicassoBarbershop = {
  img: "barbers.jpeg",
  website: "picasso-barbershop.lt",
  url: "https://picasso-barbershop.lt",
  progLanguage: "vue-js",
};

export const PicassoBarbershopApp = {
  img: "barbers.jpeg",
  website: "Picasso Barbershop App",
  url: "https://play.google.com/store/apps/details?id=com.tohirAbdujaborov.PicassoBarbershop",
  progLanguage: "react-native",
};

export const FortyTatarsVillage = {
  img: "FortyTatarsVillage.jpg",
  website: "fortytatarsvillage.lt",
  url: "https://fortytatarsvillage.lt",
  progLanguage: "php",
};

export const Piktransport = {
  img: "piktransport.jpg",
  website: "piktransport.com",
  url: "https://piktransport.com",
  progLanguage: "php",
};

export const MFuel = {
  img: "mfuel.jpg",
  website: "mfuel - car expenses",
  url: "https://play.google.com/store/apps/details?id=com.my.autodegalas",
  progLanguage: "android",
};

export const MyLL = {
  img: "myll.jpg",
  website: "My LL - Get your longitude and latitude",
  url: "https://play.google.com/store/apps/details?id=com.e.myll",
  progLanguage: "android",
};

export const Book2play = {
  img: "book2play.png",
  website: "book2play.eu",
  url: "https://book2play.eu",
  progLanguage: "javascript",
};

export const Portfolio = {
  img: "2.png",
  website: "tohir.lt",
  url: "https://tohirlt.netlify.app/",
  progLanguage: "react-app",
};
