import { useState, useEffect, useLayoutEffect } from "react";
import Devider from "../../ui/Devider";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  const [isMobile, setMobile] = useState(false);

  const openSocials = (socialNetwork) => {
    const fb_url = "https://www.facebook.com/tohir.abdujaborov/";
    const insta_url = "https://www.instagram.com/tahiras.abdujaborovas/";
    const linkedin_url = "https://www.linkedin.com/in/tahiras-abdujaborovas-798618161/";
    switch (socialNetwork) {
      case "Facebook":
        window.open(fb_url);
        break;
      case "Instagram":
        window.open(insta_url);
        break;
      case "LinkedIn":
        window.open(linkedin_url);
        break;
      default:
    }
    // socialNetwork === "Facebook" ? window.open(fb_url) : window.open(insta_url);
  };

  useEffect(() => {
    let windowWitdh = window.innerWidth;
    windowWitdh < 1000 ? setMobile(true) : setMobile(false);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      let windowWitdh = window.innerWidth;
      windowWitdh < 1000 ? setMobile(true) : setMobile(false);
    });
  }, []);

  return (
    <section id="socialIcons" className="container">
      <Devider marginBetween={100} />
      <div className="row justify-content-center text-center">
        <div
          className={
            isMobile ? "col-lg-1 followLinks pb-4" : "col-lg-1 followLinks"
          }
          onClick={() => openSocials("Facebook")}
        >
          <i className="fa fa-facebook-square fa-2x"></i>
          <br />
          <span>Facebook</span>
        </div>
        <div
          className="col-lg-1 followLinks"
          onClick={() => openSocials("Instagram")}
        >
          <i className="fa fa-instagram fa-2x"></i>
          <br />
          <span>Instagram</span>
        </div>
        <div
          className={
            isMobile ? "col-lg-1 followLinks pt-4" : "col-lg-1 followLinks"
          }
          onClick={() => openSocials("LinkedIn")}
        >
          <i className="fa fa-linkedin fa-2x"></i>
          <br />
          <span>LinkedIn</span>
        </div>
      </div>
      <div className="socialsFollowMe">{t("Footer.followMe")}</div>
    </section>
  );
}
