import { useEffect, useState, useLayoutEffect } from "react";
import Project from "./Project";
import {
  Portfolio,
  PicassoBarbershop,
  FortyTatarsVillage,
  Piktransport,
  Book2play,
  PicassoBarbershopApp,
  MFuel,
  MyLL,
} from "./data";
import { useTranslation } from "react-i18next";

const AllProjects = () => {
  const { t } = useTranslation();

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    let windowWitdh = window.innerWidth;
    windowWitdh < 768 ? setMobile(true) : setMobile(false);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      let windowWitdh = window.innerWidth;
      windowWitdh < 768 ? setMobile(true) : setMobile(false);
    });
  }, []);

  return (
    <div>
      <div className="row">
        <div className={`col-md-3 ${isMobile ? "pb-4" : ""}`}>
          <Project
            img={Portfolio.img}
            imgAlt={Portfolio.img}
            progLanguage={Portfolio.progLanguage}
            title={Portfolio.website}
            description={t("Projects.Portfolio.description")}
            url={Portfolio.url}
          />
        </div>
        <div className={`col-md-3 ${isMobile ? "pb-4" : ""}`}>
          <Project
            img={Book2play.img}
            imgAlt={Book2play.img}
            progLanguage={Book2play.progLanguage}
            title={Book2play.website}
            description={t("Projects.Book2play.description")}
            url={Book2play.url}
          />
        </div>
        <div className={`col-md-3 ${isMobile ? "pb-4" : ""}`}>
          <Project
            img={PicassoBarbershopApp.img}
            imgAlt={PicassoBarbershopApp.img}
            progLanguage={PicassoBarbershopApp.progLanguage}
            title={PicassoBarbershopApp.website}
            description={t("Projects.PicassoBarbershopApp.description")}
            url={PicassoBarbershopApp.url}
          />
        </div>
        <div className={"col-md-3"}>
          <Project
            img={FortyTatarsVillage.img}
            imgAlt={FortyTatarsVillage.img}
            progLanguage={FortyTatarsVillage.progLanguage}
            title={FortyTatarsVillage.website}
            description={t("Projects.FortyTatarsVillage.description")}
            url={FortyTatarsVillage.url}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className={`col-md-3 ${isMobile ? "pb-4" : ""}`}>
          <Project
            img={PicassoBarbershop.img}
            imgAlt={PicassoBarbershop.img}
            progLanguage={PicassoBarbershop.progLanguage}
            title={PicassoBarbershop.website}
            description={t("Projects.PicassoBarbershop.description")}
            url={PicassoBarbershop.url}
          />
        </div>
        <div className={`col-md-3 ${isMobile ? "pb-4" : ""}`}>
          <Project
            img={Piktransport.img}
            imgAlt={Piktransport.img}
            progLanguage={Piktransport.progLanguage}
            title={Piktransport.website}
            description={t("Projects.Piktransport.description")}
            url={Piktransport.url}
          />
        </div>
        <div className={`col-md-3 ${isMobile ? "pb-4" : ""}`}>
          <Project
            img={MFuel.img}
            imgAlt={MFuel.img}
            progLanguage={MFuel.progLanguage}
            title={MFuel.website}
            description={t("Projects.MFuel.description")}
            url={MFuel.url}
          />
        </div>
        <div className="col-md-3">
          <Project
            img={MyLL.img}
            imgAlt={MyLL.img}
            progLanguage={MyLL.progLanguage}
            title={MyLL.website}
            description={t("Projects.MyLL.description")}
            url={MyLL.url}
          />
        </div>
      </div>
    </div>
  );
};
export default AllProjects;
