import React, { useState, useLayoutEffect, useEffect } from "react";
import Devider from "../ui/Devider";
import { contentMargins } from "../styles/Globals";
import emailCheckMark from "../assets/emailCheckMark.png";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { sendForm } from "../api/urls";

export default function Contacts() {
  const [loading, isLoadingActive] = useState(false);
  const [messageSent, setMessageSend] = useState(false);
  const [isButtonDisabled, setButtonStatus] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [textareaMobile, settextareaMobile] = useState(false);

  function onFormSubmit(e) {
    e.preventDefault();
    sendData(name, email, message);
  }

  function sendData(name, email, message) {
    isLoadingActive(true);
    axios
      .get(sendForm, {
        params: {
          name: name,
          email: email,
          message: message,
        },
      })
      .then((Response) => {
        isLoadingActive(false);
        let data = Response.data;
        if (data === "Message sent!") {
          setMessageSend(true);
          clearAllFields();
          setButtonStatus(true);
        }
      })
      .catch((error) => {
        console.log("Error: " + error.message);
      });
  }
  function clearAllFields() {
    setName("");
    setEmail("");
    setMessage("");
  }
  useEffect(() => {
    let windowWitdh = window.innerWidth;
    windowWitdh < 768 ? settextareaMobile(true) : settextareaMobile(false);
  }, []);
  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      let windowWitdh = window.innerWidth;
      windowWitdh < 768 ? settextareaMobile(true) : settextareaMobile(false);
    });
  }, []);
  const { t } = useTranslation();
  return (
    <section>
      <Devider marginBetween={contentMargins.marginTop} />
      <div
        className={
          textareaMobile ? "ContactsGetInMobile" : "ContactsGetInTouch"
        }
        dangerouslySetInnerHTML={{ __html: t("Contacts.getInTouch") }}
      />
      <Devider marginBetween={50} />
      <div className="container">
        <form onSubmit={onFormSubmit}>
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="input-container">
                <i className="fa fa-user-o icon"></i>
                <input
                  className="input-field"
                  type="text"
                  placeholder={t("Contacts.nameOrCompany")}
                  maxLength="255"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <Devider marginBetween={textareaMobile ? 30 : 60} />
              <div className="input-container">
                <i className="fa fa-envelope-o icon"></i>
                <input
                  className="input-field"
                  type="email"
                  placeholder={t("Contacts.email")}
                  maxLength="255"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-5">
              {textareaMobile ? (
                <Devider marginBetween={textareaMobile ? 30 : 60} />
              ) : (
                ""
              )}
              <textarea
                id="ContactTextArea"
                rows="6"
                placeholder={t("Contacts.message")}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>
            <div className="col-md-9 mt-5">
              <center>
                <img
                  src={emailCheckMark}
                  width="30"
                  alt="Email checkmark icon | Tohir.lt"
                />
                <span id="ContactsCheckMark">{t("Contacts.directEmail")}</span>
              </center>
            </div>
            <div className="col-md-9 mt-2">
              <Devider marginBetween={30} />
              <center>
                <button
                  type="submit"
                  className="btn"
                  id="ContactsSubmitButton"
                  disabled={isButtonDisabled}
                >
                  <LoadingSpinner isVisible={loading} />
                  {!messageSent && !loading ? (
                    <i className="fa fa-paper-plane fa-md"></i>
                  ) : (
                    ""
                  )}
                  {messageSent && !loading ? (
                    <i className="fa fa-check fa-md"></i>
                  ) : (
                    ""
                  )}
                  &nbsp;
                  {messageSent
                    ? t("Contacts.messageSent")
                    : t("Contacts.sendMessage")}
                </button>
              </center>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
function LoadingSpinner(props) {
  const spinner = (
    <div
      v-if="loadingVisibility"
      className="spinner-border text-light spinner-border-sm"
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
  if (props.isVisible) return spinner;
  else return "";
}
