import { createStore, combineReducers } from "redux";
import languageReducer from "./language/Reducers";
import drawerReducer from "./menu/Reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const allReducers = combineReducers({
  language: languageReducer,
  menu: drawerReducer,
});

let store = createStore(allReducers, composeWithDevTools());

export default store;
