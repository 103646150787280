import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/App.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ui/translation/i18next";
import Spinner from "./components/spinner/";

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);
reportWebVitals();
